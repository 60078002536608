import { connect } from 'react-redux';
import { InfoIcon } from 'icons/info';
import { AppState } from 'redux/store';
import { Tooltip } from '@mui/material';
import { IdName } from 'types/common-types';
import { XCrossIcon2 } from 'icons/x-cross-2';
import { AppLanguage } from 'types/common-enums';
import Spinner from 'components/spinner/spinner';
import Checkbox from 'components/checkbox/checkbox';
import { FC, useEffect } from 'react';
import { LOGO_FILES_TYPES } from 'constants/file-types';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import FileUpload from 'components/file-upload/file-upload';
import { CompanyLogoFileTypesEnum } from 'types/file-enums';
import WidthContainer from 'components/width-container/width-container';
import InputWithButtons from 'components/ui-new/input-with-buttons/input-with-buttons';
import { Actions as SettingsActions, inputNames, LanguageName } from 'pages/settings/settings.controller';
import DropdownSelector from 'components/ui-new/dropdown-permission-selector/dropdown-permission-selector';
import {
  abbreviationFormatter,
  currencyFormatter,
  onlyNumbersFormatter,
  reportingPeriodFormatter,
} from 'pages/settings/helpers/value-formatter';
import s from './settings.module.scss';

const skeleton = (
  <div className={s.skeleton}>
    <Skeleton variant="text" width="120px" height={36} style={{ marginBottom: 24 }} />
    <Skeleton variant="text" width="175px" height={27} style={{ marginBottom: 24 }} />
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="104px" height={21} />
      <Skeleton variant="text" height={68} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="114px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="370px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="65px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="213px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row} style={{ marginBottom: 55 }}>
      <Skeleton variant="text" width="225px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <Skeleton variant="text" width="100px" height={27} style={{ marginBottom: 24 }} />
    <div className={s.skeleton_row} style={{ marginBottom: 23 }}>
      <Skeleton variant="text" width="454px" height={21} />
      <Skeleton variant="text" height={40.13} style={{ maxWidth: 500, width: '40%' }} />
    </div>
    <div className={s.skeleton_row}>
      <Skeleton variant="text" width="442px" height={21} />
      <Skeleton variant="text" height={20} width={20} />
    </div>
  </div>
);

type StateProps = {
  language: IdName;
  currency: string;
  companyName: string;
  isFetching: boolean;
  abbreviation: string;
  isLogoLoading: boolean;
  analyticalLink: string;
  companyLogoUrl: string;
  companyLogoType: string;
  companyLogoName: string;
  defaultDeadline: number;
  // priorityTaskItemByXDays: number;
  isDeadlineFromExternal: boolean;
  // priorityProductionItemByXDays: number;
  isAllowedUndoChangingTaskStatus: boolean;
  // updateTaskPriorityBeforeDeadline: boolean;
  // updateProductionPriorityBeforeDeadline: boolean;
  correctionDaysAfterReachingReportingPeriod: number;
};
type DispatchProps = {
  init: () => void;
  removeCompanyLogo: () => {};
  uploadCompanyLogo: (file: any) => void;
  onChangeDeadlineFromExternal: () => void;
  onChangeLanguage: (value: IdName) => void;
  onChangeUndoChangingTaskStatus: () => void;
  // onChangePriorityTaskItemByXDays: () => void;
  // onChangePriorityProductionItemByXDays: () => void;
  onSaveInputValue: (inputName: inputNames, value: string | number) => void;
};
type Props = StateProps & DispatchProps;

const Settings: FC<Props> = ({
  language,
  currency,
  isFetching,
  companyName,
  abbreviation,
  isLogoLoading,
  companyLogoUrl,
  analyticalLink,
  companyLogoName,
  companyLogoType,
  defaultDeadline,
  isDeadlineFromExternal,
  // priorityTaskItemByXDays,
  // priorityProductionItemByXDays,
  isAllowedUndoChangingTaskStatus,
  // updateTaskPriorityBeforeDeadline,
  // updateProductionPriorityBeforeDeadline,
  correctionDaysAfterReachingReportingPeriod,
  init,
  onSaveInputValue,
  onChangeLanguage,
  uploadCompanyLogo,
  removeCompanyLogo,
  onChangeDeadlineFromExternal,
  onChangeUndoChangingTaskStatus,
  // onChangePriorityTaskItemByXDays,
  // onChangePriorityProductionItemByXDays,
}) => {
  const options = {
    appLanguage: [
      {
        id: AppLanguage.English,
        name: LanguageName.English,
      },
      {
        id: AppLanguage.Ukrainian,
        name: LanguageName.Ukrainian,
      },
    ],
  };

  useEffect(() => {
    document.title = 'Settings';
    init();
  }, []);

  return (
    <WidthContainer>
      <div className={s.container}>
        {isFetching ? (
          skeleton
        ) : (
          <>
            <h1 className={s.page_title}>Settings</h1>
            <div className={s.column}>
              <div className={s.section}>
                <h5 className={s.subtitle}>Company overview</h5>
                <div className={s.row}>
                  <b className={s.row_title}>Company logo</b>
                  <div className={s.right_col}>
                    {companyLogoUrl ? (
                      <div className={s.logo_container}>
                        <img className={s.logo} src={companyLogoUrl} alt="logo" />
                        <div className={s.company_name_container}>
                          <p className={s.company_name}>{companyLogoName}</p>
                          <p className={s.file_type}>{companyLogoType}</p>
                        </div>
                        <XCrossIcon2 className={s.icon} onClick={removeCompanyLogo} />
                        <div className={s.backdrop} data-loading={isLogoLoading} />
                        {isLogoLoading && (
                          <div className={s.spinner_container}>
                            <Spinner containerClassName={s.spinner} disableShrink size={30} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <FileUpload
                        withSpinner
                        id="company-logo"
                        style={{ width: '100%' }}
                        isLoading={isLogoLoading}
                        acceptType={LOGO_FILES_TYPES}
                        fileTypes={CompanyLogoFileTypesEnum}
                        onChange={(file) => uploadCompanyLogo(file[0])}
                      />
                    )}
                  </div>
                </div>
                <div className={s.row}>
                  <b className={s.row_title}>Company name</b>
                  <div className={s.right_col}>
                    <InputWithButtons
                      value={companyName}
                      onConfirmClick={(value) => onSaveInputValue(inputNames.CompanyName, value)}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <div className={s.title_container}>
                    <b className={s.row_title}>Key abbreviation</b>
                    <p className={s.row_description}>Is used to generate identifiers for orders, productions and tasks</p>
                  </div>
                  <div className={s.right_col}>
                    <InputWithButtons
                      value={abbreviation}
                      valueFormatter={(newValue) => abbreviationFormatter(newValue)}
                      onConfirmClick={(value) => onSaveInputValue(inputNames.Abbreviation, value)}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <b className={s.row_title}>Currency</b>
                  <div className={s.right_col}>
                    <InputWithButtons
                      value={currency}
                      valueFormatter={(newValue) => currencyFormatter(newValue)}
                      onConfirmClick={(value) => onSaveInputValue(inputNames.Currency, value)}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <b className={s.row_title}>Mobile App Default Language</b>
                  <div className={s.right_col}>
                    <DropdownSelector
                      value={language}
                      isFullDropdownWidth
                      options={options.appLanguage}
                      onChange={(value) => onChangeLanguage(value)}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <div className={s.title_container}>
                    <b className={s.row_title}>Analytics report link</b>
                    <p className={s.row_description}>Is used to embed your web-published reports to HESH Analytics page</p>
                  </div>
                  <div className={s.right_col}>
                    <InputWithButtons
                      allowedEmpty
                      value={analyticalLink}
                      placeholder="https://..."
                      onConfirmClick={(value) => onSaveInputValue(inputNames.AnalyticalLink, value)}
                    />
                  </div>
                </div>
              </div>
              <div className={s.section}>
                <h5 className={s.subtitle}>Production</h5>
                <div className={s.row}>
                  <div className={s.title_container}>
                    <b className={s.row_title}>Default production deadline</b>
                    <p className={s.row_description}>
                      Calendar days to perform production (will set on each newly created product)
                    </p>
                  </div>
                  <div className={s.right_col}>
                    <InputWithButtons
                      value={`${defaultDeadline}`}
                      valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
                      onConfirmClick={(value) => onSaveInputValue(inputNames.DefaultDeadline, parseInt(value, 10))}
                    />
                  </div>
                </div>
                <div className={s.row} data-with-checkbox>
                  <b className={s.row_title}>Overwrite production deadline values from an external system</b>
                  <Checkbox size="medium" checked={isDeadlineFromExternal} onClick={onChangeDeadlineFromExternal} />
                </div>
                {/* <div className={s.row} data-with-checkbox>
                  <div className={s.title_container}>
                    <b className={s.row_title}>
                      Change priority of production item X days before deadline
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            &bull;If the checkbox is disabled, system won’t change priority automatically.
                            <br /> &bull;If the checkbox is enabled and the value in the input field is “0”, system will change
                            priority on the deadline day.
                          </>
                        }
                      >
                        <div className={s.icon_container}>
                          <InfoIcon className={s.info_icon} />
                        </div>
                      </Tooltip>
                    </b>
                    <b className={s.row_description}>
                      Establish number of days before deadline when system should automatically change production priority to
                      Highest
                    </b>
                  </div>
                  <div className={s.right_col}>
                    <div className={s.right_col_group}>
                      <InputWithButtons
                        value={`${priorityProductionItemByXDays}`}
                        disabled={!updateProductionPriorityBeforeDeadline}
                        valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
                        onConfirmClick={(value) =>
                          onSaveInputValue(inputNames.PriorityProductionItemByXDays, parseInt(value, 10))
                        }
                      />
                      <div className={s.checkbox_container}>
                        <span>Enable</span>
                        <Checkbox
                          size="medium"
                          checked={updateProductionPriorityBeforeDeadline}
                          onClick={onChangePriorityProductionItemByXDays}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className={s.row} data-with-checkbox>
                  <div className={s.title_container}>
                    <b className={s.row_title}>
                      Change priority of tasks of production item X days before deadline
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            &bull;If the checkbox is disabled, system won’t change priority automatically.
                            <br /> &bull;If the checkbox is enabled and the value in the input field is “0”, system will change
                            priority on the deadline day.
                          </>
                        }
                      >
                        <div className={s.icon_container}>
                          <InfoIcon className={s.info_icon} />
                        </div>
                      </Tooltip>
                    </b>
                    <b className={s.row_description}>
                      Establish number of days before deadline when system should automatically change tasks priority to Highest
                    </b>
                  </div>
                  <div className={s.right_col}>
                    <div className={s.right_col_group}>
                      <InputWithButtons
                        value={`${priorityTaskItemByXDays}`}
                        disabled={!updateTaskPriorityBeforeDeadline}
                        valueFormatter={(newValue) => onlyNumbersFormatter(newValue)}
                        onConfirmClick={(value) => onSaveInputValue(inputNames.PriorityTaskItemByXDays, parseInt(value, 10))}
                      />
                      <div className={s.checkbox_container}>
                        <span>Enable</span>
                        <Checkbox
                          size="medium"
                          checked={updateTaskPriorityBeforeDeadline}
                          onClick={onChangePriorityTaskItemByXDays}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className={s.row} data-with-checkbox>
                  <b className={s.row_title}>
                    Allow to undo changing task status from “Done” to “In progress”
                    <Tooltip
                      title="By enabling this setting, performer is able to change task status from “Done” to “In progress” during 15 sec"
                      placement="top"
                    >
                      <div className={s.icon_container}>
                        <InfoIcon className={s.info_icon} />
                      </div>
                    </Tooltip>
                  </b>
                  <Checkbox size="medium" checked={isAllowedUndoChangingTaskStatus} onClick={onChangeUndoChangingTaskStatus} />
                </div>

                <div className={s.row}>
                  <div className={s.title_container}>
                    <b className={s.row_title}>
                      Task correction days after reaching “reporting period”
                      <Tooltip
                        placement="top"
                        title={
                          <>
                            &bull; “Reporting period” is 1 calendar month. &bull; “Closed reporting period” is 1 calendar month +
                            number of days specified in this field.
                            <br /> When “closed reporting period” day has come, “Rewards” menu and “Reward summary” table can’t be
                            edited.
                          </>
                        }
                      >
                        <div className={s.icon_container}>
                          <InfoIcon className={s.info_icon} />
                        </div>
                      </Tooltip>
                    </b>
                    <b className={s.row_description}>
                      Indicates number of days when fields “Time spent” and “Reward correction” can be edited after “reporting
                      period” is reached
                    </b>
                  </div>
                  <div className={s.right_col}>
                    <InputWithButtons
                      value={`${correctionDaysAfterReachingReportingPeriod}`}
                      valueFormatter={(newValue) => reportingPeriodFormatter(newValue)}
                      onConfirmClick={(value) =>
                        onSaveInputValue(inputNames.CorrectionDaysAfterReachingReportingPeriod, parseInt(value, 10))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </WidthContainer>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  language: state.settings.language,
  currency: state.settings.currency,
  isFetching: state.settings.isFetching,
  companyName: state.settings.companyName,
  abbreviation: state.settings.abbreviation,
  analyticalLink: state.settings.analyticalLink,
  companyLogoUrl: state.settings.companyLogo.url,
  defaultDeadline: state.settings.defaultDeadline,
  isLogoLoading: state.settings.companyLogo.isLoading,
  companyLogoName: state.settings.companyLogo.fileName,
  companyLogoType: state.settings.companyLogo.fileType,
  isDeadlineFromExternal: state.settings.isDeadlineFromExternal,
  // priorityTaskItemByXDays: state.settings.priorityTaskItemByXDays,
  // priorityProductionItemByXDays: state.settings.priorityProductionItemByXDays,
  isAllowedUndoChangingTaskStatus: state.settings.isAllowedUndoChangingTaskStatus,
  // updateTaskPriorityBeforeDeadline: state.settings.updateTaskPriorityBeforeDeadline,
  // updateProductionPriorityBeforeDeadline: state.settings.updateProductionPriorityBeforeDeadline,
  correctionDaysAfterReachingReportingPeriod: state.settings.correctionDaysAfterReachingReportingPeriod,
});
const mapDispatchToProps: DispatchProps = {
  init: SettingsActions.init,
  onSaveInputValue: SettingsActions.onSaveInputValue,
  onChangeLanguage: SettingsActions.onChangeLanguage,
  removeCompanyLogo: SettingsActions.removeCompanyLogo,
  uploadCompanyLogo: SettingsActions.uploadCompanyLogo,
  onChangeDeadlineFromExternal: SettingsActions.onChangeDeadlineFromExternal,
  onChangeUndoChangingTaskStatus: SettingsActions.onChangeUndoChangingTaskStatus,
  // onChangePriorityTaskItemByXDays: SettingsActions.onChangePriorityTaskItemByXDays,
  // onChangePriorityProductionItemByXDays: SettingsActions.onChangePriorityProductionItemByXDays,
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
